import '/assets/styles/partials/tabs.scss';

import RawTabs from '@wearetla/tla-essentials-tools/partials/tabs/tabs';

const Tabs = (props) => {
	return (
		<RawTabs {...props} />
	)
}

export default Tabs;