import '/assets/styles/sections/event-programme.scss';
import { useMemo } from "react"
import classNames from "classnames";
import PropTypes from 'prop-types';

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder'
import Btn from '/views/partials/btn'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Tabs from '/views/partials/tabs';
// import { Input } from '/views/partials/forms'

export const EventProgramme = ({ event, title, className }) => {
	const programmeDays = useMemo(() => {
		const indexedDays = (event.programs ?? []).reduce((days, event) => {
			const dateKey = event.pivot.program_date;
			if(days[dateKey]) {
				return {
					...days,
					[dateKey]: {
						...days[dateKey],
						events: [
							...days[dateKey].events,
							event,
						]

					}
				}
			}
			else {
				return {
					...days,
					[dateKey]: {
						label: formatDate(dateKey, 'd MMMM y'),
						value: dateKey,
						events: [
							event,
						]
					}
				}
			}
		}, {})

		return Object.keys(indexedDays).map(dateKey => indexedDays[dateKey]);
	}, [event])

	// const [selectedDay, setSelectedDay] = useState(programmeDays?.[0]);

	// useEffect(() => {
	// 	setSelectedDay(programmeDays?.[0]);
	// }, [programmeDays])

	if(programmeDays?.length === 0) {
		return null;
	}

	return (
		<section className={classNames('section event-programme', className)}>
			{title &&
				<>
					{event ?
						<h2 className="programme-title">{title}</h2>
						:
						<Placeholder className="programme-title" />
					}
				</>
			}

			<Tabs className="programme-tabs" >
				{programmeDays.map((day) => (
					<div
						key={day.value}
						index={day.value}
						className="tabs-day"
						label={day.label}>
						{day.events?.length &&
							<ul className="programme-list">
								{day.events.map((event) => (
									<li className="list-event" key={event.id}>
										<div className="event-image">
											<div className="image-wrap">
												<Img
													className="image-image"
													alt={event.title}
													src={event.image?.asset_paths?.original} />
											</div>

										</div>
										<div className="event-text">
											<Link
												href="eventDetail"
												params={{id: event.id, slug: event.slug }}>
												<strong className="event-title">
													{event.title}
												</strong>
												<span className="event-details">
													{event.pivot.program_type} - {event.pivot.program_time}
												</span>
											</Link>
										</div>
										<div className="event-controls">
											<Btn
												tag="link"
												href="eventDetail"
												params={{id: event.id, slug: event.slug }}
												className="event-detailsbtn outline text">
												Detayları Gör
											</Btn>
										</div>
									</li>
								))}
							</ul>
						}
					</div>
				))}
			</Tabs>

			{/* <Input
				className="programme-nav"
				type="select"
				onChange={setSelectedDay}
				value={selectedDay}
				options={programmeDays} />

			{!!selectedDay?.events?.length &&
				<ul className="programme-list">
					{selectedDay.events.map((event) => (
						<li className="list-event" key={event.id}>
							<div className="event-image">
								<div className="image-wrap">
									<Img
										className="image-image"
										alt={event.title}
										src={event.image?.asset_paths?.original} />
								</div>

							</div>
							<div className="event-text">
								<Link
									href="eventDetail"
									params={{id: event.id, slug: event.slug }}>
									<strong className="event-title">
										{event.title}
									</strong>
									<span className="event-details">
										{event.pivot.program_type} - {event.pivot.program_time}
									</span>
								</Link>
							</div>
							<div className="event-controls">
								<Btn
									tag="link"
									href="eventDetail"
									params={{id: event.id, slug: event.slug }}
									className="event-detailsbtn outline text">
									Detayları Gör
								</Btn>
							</div>
						</li>
					))}
				</ul>
			} */}
			
		</section>
	)
}

EventProgramme.propTypes = {
	event: PropTypes.object,
	selectedCalendar: PropTypes.object,
	title: PropTypes.string,
	className: PropTypes.string,
}

export default EventProgramme;