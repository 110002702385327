import '/assets/styles/sections/event-calendar.scss';
import { useMemo } from "react"
import classNames from "classnames";
import PropTypes from 'prop-types';

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import createEventBtnParams from '/functions/create-event-btn-params';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder'
import Btn from '/views/partials/btn'

export const EventCalendar = ({ event, selectedCalendar, title, className }) => {
	const events = useMemo(() => {
		if(event) {
			return event.calendars.filter((e) => e.id !== selectedCalendar?.id).map((calendarItem) => {
				const date = new Date(calendarItem.full_date);

				const day = formatDate(date, 'd', { parsed: true });
				const month = formatDate(date, 'MMM', { parsed: true });
				const time = formatDate(date, 'HH:mm', { parsed: true });
	
				return {
					eventId: event.id,
					title: event.title,
					slug: event.slug,
					...calendarItem,
					day,
					month,
					time,
				}
			});
		}
		else {
			return [false, false, false, false];
		}
	}, [event, selectedCalendar])

	if(events?.length === 0) {
		return null;
	}

	return (
		<section className={classNames('section event-calendar', className)}>
			{title &&
				<>
					{event ?
						<h2 className="calendar-title">{title}</h2>
						:
						<Placeholder className="calendar-title" />
					}
				</>
			}
			
			<ul className="calendar-list">
				{events.map((calendarEvent, nth) => (calendarEvent ? (
					<li key={calendarEvent.id} className="list-event">
						<div className="event-datetime">
							<span className="datetime-day">{calendarEvent.day}</span>
							<span className="datetime-month">{calendarEvent.month}</span>
						</div>
						<div className="event-text">
							<Link
								href="eventDetail"
								params={{id: calendarEvent.eventId, slug: calendarEvent.slug, calendarId: calendarEvent.id }}>
								<strong className="event-title">
									{calendarEvent.title}
								</strong>
								<span className="event-details">
									{calendarEvent.event_location} - {calendarEvent.time}
								</span>
							</Link>
						</div>
						<div className="event-controls">
							<Btn
								{...createEventBtnParams(event, calendarEvent)}
								className="event-buybtn outline text">
								BİLET AL
							</Btn>
							<Btn
								tag="link"
								href="eventDetail"
								params={{id: calendarEvent.eventId, slug: calendarEvent.slug, calendarId: calendarEvent.id }}
								className="event-detailsbtn nude text">
								Detayları Gör
							</Btn>
						</div>
					</li>
				):(
					<li className="list-event" key={nth}>
						<Placeholder className="event-datetime" />

						<div className="event-text">
							<Placeholder className="event-title" />
							<Placeholder className="event-details" />
						</div>
						
						<div className="event-controls">
							<Placeholder className="event-buybtn" />
							<Placeholder className="event-detailsbtn" />
						</div>
					</li>
				)))}
			</ul>
		</section>
	)
}

EventCalendar.propTypes = {
	event: PropTypes.object,
	selectedCalendar: PropTypes.object,
	title: PropTypes.string,
	className: PropTypes.string,
}

export default EventCalendar;