import '/assets/styles/pages/event-detail.scss';
import PropTypes from 'prop-types';
import { useMemo, useEffect, useState } from 'react';

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import createEventBtnParams from '/functions/create-event-btn-params';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder';
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';
import Tabs from '/views/partials/tabs';
import EmptyMessage from '/views/partials/empty-message'
// import Badge from '/views/partials/badge';

// Sections
import EventCalendar from '/views/sections/event-calendar';
import EventProgramme from '/views/sections/event-programme';
import EventTicketOptions from '/views/sections/event-ticketoptions';

// Context
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Services
import eventServices from '/services/event'

const parseSelectedCalendar = (event, calendarId) => {
	const foundCalendar = event?.calendars?.length ? (calendarId ? event.calendars.find(c => c.id.toString() === calendarId.toString()) : null) : null;

	const selectedCalendar = foundCalendar ? {
		...foundCalendar,
		subFields: (() => {
			let fields = [];

			fields.push({
				icon: 'calendar',
				title: formatDate(foundCalendar.full_date, 'd MMMM'),
			})

			fields.push({
				icon: 'time',
				title: `Etkinlik - ${formatDate(foundCalendar.full_date, 'HH:mm')}`,
			})

			if(event.type) {
				fields.push({
					icon: event.type.icon,
					title: event.type.name,
				})
			}

			if(foundCalendar.door_time?.length) {
				fields.push({
					icon: 'door',
					title: 'Kapı Açılış',
					value: foundCalendar.door_time,
				})
			}

			// Buraya After Gelecek

			return fields;
		})()
	} : null;

	return [selectedCalendar, foundCalendar === undefined]
}

const getEvent = (eventId) => {
	return eventServices.getEvent(eventId);
}

const parseHead = (event, selectedCalendar, calendarNotFound) => {
	if(calendarNotFound) {
		return {
			title: 'Etkinlik Bulunamadı',
			description: `Aradığınız ${event.title} etkinliği bulunamadı.`,
			image: event.image?.asset_paths?.original,
		}
	}

	if(selectedCalendar) {
		const eventDate = formatDate(selectedCalendar.full_date, 'd MMMM');
		return {
			title: `${eventDate} ${selectedCalendar.event_location} ${event.title} Etkinliği`,
			description: `${eventDate} tarihli ${selectedCalendar.event_location} ${event.title} etkinliği IF Performance Hall'da!`,
			image: event.image?.asset_paths?.original,
		}
	}
	else {
		return {
			title: `${event.title} Etkinlikleri.`,
			description: event.short_description,
			image: event.image?.asset_paths?.original
		}
	}
}

const EventDetail = ({ pageProps }) => {
	const { triggerEvent } = useGlobalEvents();
	const { setNotFound } = useGlobalState();
	const { desktop, mobile } = useBreakpoints();
	const { setHead } = useHead();
	const [event, setEvent] = useState(pageProps.event ?? null);
	const { goBack } = useNavigator();

	const { id, calendarId } = useParams('eventDetail');

	// const event = useMemo(() => parseEvent(event), [event]);

	const [selectedCalendar, calendarNotFound] = useMemo(() => {
		return parseSelectedCalendar(event, calendarId);
	}, [event, calendarId]);

	useEffect(() => {
		triggerEvent('detailView', { calendar: selectedCalendar, event });
	}, [selectedCalendar, event])

	useEffect(() => {
		if(id && (!event || event.id.toString() !== id.toString())) {
			getEvent(id).then(setEvent).catch(() => {
				setNotFound();
			});
		}
	}, [event, id])

	useEffect(() => {
		if(event) {
			setHead(parseHead(event, selectedCalendar, calendarNotFound));
		}
	}, [event, selectedCalendar, calendarNotFound])

	return (
		<>
			<div className="detail-nav">
				{mobile &&
					<button
						type="button"
						onClick={() => {
							goBack('events');
						}}
						className="nav-backbtn">
						<Icon name="angle-left" /> <span>Geri Git</span>
					</button>
				}
				<div className="nav-breadcrumbs">
					<div className="wrapper narrow breadcrumbs-wrap">
						<ol
							className="breadcrumbs-list"
							itemScope
							itemType="https://schema.org/BreadcrumbList">
							{event ?
								<>
									<li
										className="list-item"
										itemProp="itemListElement"
										itemScope
										itemType="https://schema.org/ListItem">
										<Link
											itemProp="item"
											className="item-link hyperlink"
											href="events">
											<span itemProp="name">Etkinlikler</span>
										</Link>
										<meta itemProp="position" content="1" />
									</li>
									<li
										className="list-item"
										itemProp="itemListElement"
										itemScope
										itemType="https://schema.org/ListItem">
										<Link
											itemProp="item"
											className="item-link hyperlink"
											href="eventDetail"
											params={{ id: event.id, slug: event.slug }}>
											<span itemProp="name">{event.title}</span>
										</Link>
										<meta itemProp="position" content="2" />
									</li>
									{selectedCalendar &&
										<li
											className="list-item"
											itemProp="itemListElement"
											itemScope
											itemType="https://schema.org/ListItem">
											<Link
												itemProp="item"
												className="item-link hyperlink"
												href="eventDetail"
												params={{ id: event.id, slug: event.slug, calendarId: selectedCalendar.id }}>
												<span itemProp="name">{selectedCalendar.event_location}</span>
											</Link>
											<meta itemProp="position" content="3" />
										</li>
									}
								</>
								:
								<>
									<li className="list-item">
										<Placeholder className="item-link" />
									</li>
									<li className="list-item">
										<Placeholder className="item-link" />
									</li>
									<li className="list-item">
										<Placeholder className="item-link" />
									</li>
								</>
							}
						</ol>
					</div>
				</div>
			</div>
			<div className="wrapper narrow detail-wrap">
				{event ?
					<>						
						<div className="detail-content">
							{mobile &&
								<div className="detail-image">
									<div className="image-imagewrap">
										<Img
											cover
											lazy={false}
											src={event.image?.asset_paths?.original} />
									</div>
								</div>
							}
							<main className="detail-info">
								<h1 className="info-title">{event.title} {selectedCalendar && <span className="title-subtitle">{selectedCalendar.event_location}</span>}</h1>
								{selectedCalendar &&
									<>
										<Link
											className="info-eventlink"
											href="eventDetail"
											title={`Tüm ${event.title} etkinlikleri`}
											params={{ id: event.id, slug: event.slug }}>
											{event.type?.name === 'Konser' ? 'Sanatçı' : 'Etkinlik'} sayfasına git
										</Link>
									</>
								}

								{(!selectedCalendar && !!event.short_description) &&
									<div className="info-shortdescription">
										{event.short_description}
									</div>
								}

								{calendarNotFound &&
									<EmptyMessage>Aradığınız etkinlik bulunamadı.</EmptyMessage>
								}

								{selectedCalendar &&
									<>
										{/* <div className="info-fields">
											<Badge
												className="fields-field"
												big
												outline
												variant="bg"
												icon="time">
												{selectedCalendar.dateTimeString}
											</Badge>
											<Badge
												className="fields-field"
												big
												outline
												variant="bg"
												icon="map-pin">
												{selectedCalendar.event_location}
											</Badge>
										</div> */}

										{!!selectedCalendar.subFields?.length &&
											<div className="info-subfields">
												{selectedCalendar.subFields.map((field, nth) => (
													<div className="subfields-field" key={nth}>
														<Icon className="field-icon" name={field.icon} />
														<span>{field.title}{field.value && <> - {field.value}</>}</span>
													</div>
												))}
											</div>
										}

										{selectedCalendar.hero_message?.length &&
											<div className="info-heromessage">
												{selectedCalendar.hero_message}
											</div>
										}

										<EventTicketOptions
											className="info-ticketoptions"
											event={event}
											selectedCalendar={selectedCalendar} />

										{(selectedCalendar.options.length === 1 || selectedCalendar.buy_btn_link) &&
											<Btn
												{...createEventBtnParams(event, selectedCalendar)}
												wrapText
												className="info-buybtn big text-big primary">
												{selectedCalendar.buy_btn_text ? selectedCalendar.buy_btn_text : <>BİLET AL - <Pricebox price={selectedCalendar.options[0].price} /></>}
											</Btn>
										}
									</>
								}
							</main>

							<EventCalendar
								className="detail-eventcalendar"
								title={`${selectedCalendar ? 'Diğer' : 'Yaklaşan'} Performanslar`}
								event={event}
								selectedCalendar={selectedCalendar} />

							<EventProgramme
								className="detail-eventprogramme"
								title="Festival Programı"
								event={event} />

							<Tabs className="detail-additions" key={selectedCalendar?.id}>
								{(!selectedCalendar && event.artist_detail) &&
									<div
										index="artistInfo"
										className="wysiwyg additions-textfield"
										label="Sanatçı Detayı"
										content={event.artist_detail}>
									</div>
								}
								{!!selectedCalendar?.event_rules &&
									<div
										index="returnRules"
										className="wysiwyg additions-textfield"
										label="Etkinlik Kuralları"
										content={selectedCalendar.event_rules}>
									</div>
								}
								{!!selectedCalendar?.refund_rules &&
									<div
										index="returnTerms"
										className="wysiwyg additions-textfield"
										label="İade Koşulları"
										content={selectedCalendar.refund_rules}>
									</div>
								}
								{/* {event.hero_description &&
									<div
										index="seatingOrder"
										className="wysiwyg additions-textfield"
										label="Oturma Düzeni"
										content={event.hero_description}>
									</div>
								} */}
							</Tabs>
						</div>

						{desktop &&
							<div className="detail-image">
								<div className="image-imagewrap">
									<Img
										cover
										lazy={false}
										src={event.image?.asset_paths?.original} />
								</div>
							</div>
						}
					</>
					:
					<>
						<div className="detail-content">
							{mobile &&
								<div className="detail-image">
									<Placeholder className="image-imagewrap" />
								</div>
							}
							<main className="detail-info">
								<Placeholder className="info-title" />

								{calendarId &&
									<Placeholder className="info-eventlink" />
								}

								{!calendarId &&
									<Placeholder className="info-shortdescription" />
								}

								<div className="info-fields">
									<Placeholder className="fields-field" />
									<Placeholder className="fields-field" />
								</div>

								<div className="info-subfields">
									<Placeholder className="subfields-field" />
									<Placeholder className="subfields-field" />
								</div>

								<Placeholder className="info-buybtn" />
							</main>

							<EventCalendar
								className="detail-eventcalendar"
								title="Yaklaşan Performanslar"
								event={event} />
						</div>

						{desktop &&
							<div className="detail-image">
								<Placeholder className="image-imagewrap" />
							</div>
						}
					</>
				}
			</div>
		</>
	)
}

EventDetail.propTypes = {
	pageProps: PropTypes.object
}

EventDetail.getServerProps = ({ match, /*mediaStatus*/ }) => {
	return new Promise((resolve) => {
		Promise.all([
			getEvent(match.params.id),
		]).then(([
			event,
		]) => {
			const [selectedCalendar, calendarNotFound] = parseSelectedCalendar(event, match.params.calendarId);

			resolve({
				pageProps: {
					event,
				},
				head: parseHead(event, selectedCalendar, calendarNotFound),
			})
		}).catch(() => {
			resolve({
			})
		})
	});
}

export default EventDetail