import '/assets/styles/sections/event-ticketoptions.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Functions
import createEventBtnParams from '/functions/create-event-btn-params';

// Partials
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';

export const EventTicketOptions = ({ event, selectedCalendar, className }) => {
	if(selectedCalendar?.options.length > 1 && !selectedCalendar.buy_btn_link) {
		return (
			<div id="event-ticketoptions" className={classNames('section', 'event-ticketoptions', className)}>
				<h2 className="ticketoptions-title">Bilet Seçenekleri</h2>
				<ul className="ticketoptions-list">
					{selectedCalendar.options.map((option) => (
						<li className="list-option" key={option.id}>
							<strong className="option-title">
								{option.title}
							</strong>
							<Btn
								{...createEventBtnParams(event, selectedCalendar, option)}
								wrapText
								className="option-buybtn primary small">
								Satın Al - <Pricebox price={option.price} />
							</Btn>
						</li>
					))}
				</ul>
			</div>
		)
	}

	return null;
}

EventTicketOptions.propTypes = {
	className: PropTypes.string,
	event: PropTypes.object,
	selectedCalendar: PropTypes.object,
}

export default EventTicketOptions;